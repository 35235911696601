<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Patellae</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="patellas-form">
          <v-text-field
            label="Result"
            v-model="fields.orthopedic_patellas_result"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('orthopedic_patellas_result')"
            :error-messages="errors['orthopedic_patellas_result']"
          ></v-text-field>

          <v-text-field
            label="Comment"
            v-model="fields.orthopedic_patellas_comment"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('orthopedic_patellas_comment')"
            :error-messages="errors['orthopedic_patellas_comment']"
          ></v-text-field>

          <v-file-input
            label="Doc. Upload"
            v-model="fields.orthopedic_patellas_document"
            placeholder="Choose a File"
            @change="urlHasChanged()"
            outlined
            :error="errors.hasOwnProperty('orthopedic_patellas_document')"
            :error-messages="errors['orthopedic_patellas_document']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="patellas-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        orthopedic_patellas_document: null,
        orthopedic_patellas_comment: null,
        orthopedic_patellas_result: null,
        document_has_changed: false,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (model = null) {
      if (model !== null) {
        this.model = model;

        this.fields.orthopedic_patellas_document =
          model.orthopedic_patellas_document
            ? new File(
                [model.orthopedic_patellas_document],
                model.orthopedic_patellas_document
              )
            : null;

        this.fields.orthopedic_patellas_comment =
          model.orthopedic_patellas_comment;
        this.fields.orthopedic_patellas_result =
          model.orthopedic_patellas_result;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.fields.formName = "patellae";

      this.$store
        .dispatch("lhl/dogs/saveTesting", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    urlHasChanged() {
      this.fields.document_has_changed = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.orthopedic_patellas_document = null;
      this.fields.orthopedic_patellas_comment = null;
      this.fields.orthopedic_patellas_result = null;
      this.fields.document_has_changed = false;
    },
  },
};
</script>
