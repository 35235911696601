<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit {{ type_text }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="dna-form">
          <v-text-field
            label="Result"
            v-model="fields.result"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('result')"
            :error-messages="errors['result']"
          ></v-text-field>

          <v-text-field
            label="Comment"
            v-model="fields.comment"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('comment')"
            :error-messages="errors['comment']"
          ></v-text-field>

          <v-file-input
            label="Doc. Upload"
            v-model="fields.document"
            placeholder="Choose a File"
            outlined
            @change="urlHasChanged()"
            :error="errors.hasOwnProperty('document')"
            :error-messages="errors['document']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="dna-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        document: null,
        comment: null,
        result: null,
        document_has_changed: false,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      type_text: null,
      type: null,
    };
  },

  methods: {
    urlHasChanged() {
      this.fields.document_has_changed = true;
    },

    openForm: function (model = null, type, type_text) {
      this.type_text = type_text;

      if (model !== null) {
        this.model = model;

        this.fields.document = model.orthopedic_elbow_document
          ? new File(
              [model.orthopedic_elbow_document],
              model.orthopedic_elbow_document
            )
          : null;

        if (type == "vwd") {
          this.fields.comment = model.dna_vwd_comment;
          this.fields.result = model.dna_vwd_result;
          this.fields.document = model.dna_vwd_document
            ? new File([model.dna_vwd_document], model.dna_vwd_document)
            : null;
        }

        if (type == "eic") {
          this.fields.comment = model.dna_eic_comment;
          this.fields.result = model.dna_eic_result;
          this.fields.document = model.dna_eic_document
            ? new File([model.dna_eic_document], model.dna_eic_document)
            : null;
        }
        if (type == "dm") {
          this.fields.comment = model.dna_dm_comment;
          this.fields.result = model.dna_dm_result;
          this.fields.document = model.dna_dm_document
            ? new File([model.dna_dm_document], model.dna_dm_document)
            : null;
        }
        if (type == "ic") {
          this.fields.comment = model.dna_ic_comment;
          this.fields.result = model.dna_ic_result;
          this.fields.document = model.dna_ic_document
            ? new File([model.dna_ic_document], model.dna_ic_document)
            : null;
        }
        if (type == "prcd_pra") {
          this.fields.comment = model.dna_prcd_pra_comment;
          this.fields.result = model.dna_prcd_pra_result;
          this.fields.document = model.dna_prcd_pra_document
            ? new File(
                [model.dna_prcd_pra_document],
                model.dna_prcd_pra_document
              )
            : null;
        }

        if (type == "pra") {
          this.fields.comment = model.dna_pra_comment;
          this.fields.result = model.dna_pra_result;
          this.fields.document = model.dna_pra_document
            ? new File([model.dna_pra_document], model.dna_pra_document)
            : null;
        }

        if (type == "news") {
          this.fields.comment = model.dna_news_comment;
          this.fields.result = model.dna_news_result;
          this.fields.document = model.dna_news_document
            ? new File([model.dna_news_document], model.dna_news_document)
            : null;
        }

        this.isEditing = true;
      }

      this.type = type;

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.fields.formName = this.type;

      this.$store
        .dispatch("lhl/dogs/saveTesting", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.document = null;
      this.fields.comment = null;
      this.fields.result = null;
      this.type_text = null;
      this.type = null;
      this.fields.document_has_changed = false;
    },
  },
};
</script>
