<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Hips</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="hips-form">
          <v-text-field
            label="Result"
            v-model="fields.orthopedic_hips_result"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('orthopedic_hips_result')"
            :error-messages="errors['orthopedic_hips_result']"
          ></v-text-field>

          <v-text-field
            label="Comment"
            v-model="fields.orthopedic_hips_comment"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('orthopedic_hips_comment')"
            :error-messages="errors['orthopedic_hips_comment']"
          ></v-text-field>

          <v-file-input
            label="Doc. Upload"
            v-model="fields.orthopedia_hips_document"
            placeholder="Choose a File"
            @change="urlHasChanged()"
            outlined
            :error="errors.hasOwnProperty('orthopedia_hips_document')"
            :error-messages="errors['orthopedia_hips_document']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="hips-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        orthopedia_hips_document: null,
        orthopedic_hips_comment: null,
        orthopedic_hips_result: null,
        document_has_changed: false,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  methods: {
    openForm: function (model = null) {
      if (model !== null) {
        this.model = model;

        this.fields.orthopedia_hips_document = model.orthopedia_hips_document
          ? new File(
              [model.orthopedia_hips_document],
              model.orthopedia_hips_document
            )
          : null;

        this.fields.orthopedic_hips_comment = model.orthopedic_hips_comment;
        this.fields.orthopedic_hips_result = model.orthopedic_hips_result;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.fields.formName = "hips";

      this.$store
        .dispatch("lhl/dogs/saveTesting", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    urlHasChanged() {
      this.fields.document_has_changed = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.orthopedia_hips_document = null;
      this.fields.orthopedic_hips_comment = null;
      this.fields.orthopedic_hips_result = null;
      this.fields.document_has_changed = false;
    },
  },
};
</script>
